.wrapper-catalog {
  padding-top: 25px;
}
.wrapper-catalog .products-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.wrapper-catalog .products-wrapper .Zoom {
  width: 315px !important;
  height: 290px !important;
}
.wrapper-catalog .products-wrapper .Qrcode {
  float: left;
  margin: 5px;
  border-radius: 8px;
  box-shadow: -2px 2px #ecf0f3;
  background-size: cover;
  background-position: center;
  border: 2px #3dcf3d dashed !important;
  opacity: 0.8;
  width: auto !important;
  height: auto !important;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.wrapper-catalog .products-wrapper .Qrcode:hover {
  border: 2px #03a9f4 dashed !important;
}
.wrapper-catalog .products-wrapper .QrcodeSelected {
  border: 2px #03a9f4 dashed !important;
}
.wrapper-catalog .products-wrapper .product {
  float: left;
  margin: 5px;
  border-radius: 8px;
  box-shadow: -2px 2px #ecf0f3;
  background-size: cover;
  background-position: center;
  border: 5px #cdd2cd solid;
  opacity: 0.8;
  width: 215px;
  height: 190px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.wrapper-catalog .products-wrapper .product div .QUnV_ {
  color: white;
  background-color: darkmagenta;
  height: 39px;
  width: 43px;
  text-align: center;
  margin: 10px;
  padding: 5px;
  border-radius: 4px;
  font-size: 21px;
}
.wrapper-catalog .products-wrapper .product div ._code_ {
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  color: #ffffff;
  margin: auto auto 15px auto;
  padding: 1px 6px 1px 6px;
  background-color: #238dd9;
  font-size: 14px;
}
.wrapper-catalog .products-wrapper .product:hover {
  border: #ffb22be8 solid 5px;
  opacity: 0.9;
  cursor: pointer;
}
.wrapper-catalog .products-wrapper .Selected {
  border: #13a0e3 solid 5px;
  opacity: 0.9;
  cursor: pointer;
}
.wrapper-catalog .products-wrapper .item-checked {
  border: #ffb22be8 solid 5px;
  opacity: 0.9;
  cursor: pointer;
}
.wrapper-catalog .products-wrapper i {
  margin: 8px;
  color: #161816;
  font-size: 20px;
}
.wrapper-catalog .products-wrapper .eyeView {
  float: right;
  color: #7a9999;
}
.wrapper-catalog .products-wrapper ._code_ strong {
  color: #ffffff;
}

@media print {
  /* styles here */
  #root > div > main > div.NavControls {
    display: none;
  }

  #root > div > header > div > div.userInfo {
    display: none;
  }

  #root > div > header > div > div.sysOptions {
    display: none;
  }

  .LeftSidebar,
footer {
    display: none;
  }

  .product {
    width: 30%;
  }
}