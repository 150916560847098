.modal-content-clint {
  background-color: white;
  direction: rtl;
  display: flex;
  justify-content: center;
  padding: 75px;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  border: double 10px rgba(196, 196, 196, 0.14);
  margin: 5%;
  margin-top: 10%;
  max-width: 760px !important;
}
.modal-content-clint h1 {
  color: #048ee4;
  margin: 2px;
  font: 2em "Tajawal" !important;
  font-weight: bold;
}
.modal-content-clint div {
  text-align: right;
  direction: rtl;
}
.modal-content-clint div h3 {
  color: #0c642e;
  margin: 2px;
  font: 2em "Tajawal" !important;
  font-weight: bold;
}
.modal-content-clint div h3 a {
  color: #0e83f1;
}