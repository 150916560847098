.wrapper-NotInOrder {
  padding-top: 25px;
  display: flex;
  justify-content: space-evenly;
}
.wrapper-NotInOrder .fixContainer {
  width: 20%;
  height: -moz-fit-content;
  height: fit-content;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item {
  width: inherit !important;
  position: fixed;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  padding: 16px;
  margin-right: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item h2 {
  text-align: right !important;
  color: #13a0e3;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .Opt-bar {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .Opt-bar .pushAction {
  padding: 5px;
  border-radius: 10px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 23px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px #85c7e5 dashed;
  margin-left: 15px;
  margin-right: 7px;
  color: darkorange;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item hr {
  border-top: 1px dashed #21aced;
  margin-top: 5px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item #transparent-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(243, 242, 242, 0);
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0%;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item #transparent-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: rgba(187, 186, 186, 0);
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item #transparent-scroll::-webkit-scrollbar-thumb {
  background-color: #ffffff00;
  border-radius: 0px;
  background-image: -webkit-linear-gradient(50deg, rgba(255, 255, 255, 0) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0) 50%, rgba(250, 250, 250, 0) 75%, transparent 85%, transparent);
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers {
  min-height: 300px;
  max-height: 300px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 5px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item {
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin: 5px 0px 5px 0px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .item {
  height: 52px;
  width: 52px;
  margin: 5px;
  border: 2px solid #ecf0f3;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder {
  background-color: #f7f7f7;
  border-radius: 0px 0px 15px 15px;
  height: fit-content;
  width: 80%;
  padding-bottom: 10px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder h3 {
  font-family: "Amiri", serif;
  margin: 5px;
  margin-left: 18px;
  color: #479d5a;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder h3 .hv {
  color: #5aaff7;
  font-size: x-small;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint {
  height: 20px;
  font-size: small;
  direction: ltr;
  margin: 4px 0px 4px 15px;
  font-family: "Amiri", serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint .Check-inOrder {
  height: 15px;
  width: 15px;
  border: 1px solid #ffb22b7a;
  margin: 5px;
  border-radius: 3px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint .Check-inOrder:hover {
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint .Checked-true {
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint strong {
  color: #266c8d;
  font-family: "Amiri", serif;
  padding: 0 5px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint span {
  color: #0e100fac;
  font-family: "Amiri", serif;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint .QV {
  background-color: #5aaff7;
  margin: 5px;
  padding: 2px 5px;
  color: #fff;
  font-style: revert;
  border-radius: 3px;
  font-family: cursive;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .containers .header-item .clintOrder .clint .fa-folder {
  color: #64dafc;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList {
  height: 320px;
  overflow: scroll;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Report {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Report .ItemReport {
  background-color: #f0f8ff;
  border: 1px dashed #a7d7ed;
  border-radius: 6px;
  display: flex;
  margin: 2px;
  padding: 10px;
  width: 98%;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Report .ItemReport div h1 {
  color: lightslategray;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Report .ItemReport h2 {
  color: #6bb8db;
  font-size: 12px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier {
  display: flex;
  margin: 5px 0px 5px 0px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  text-align: right;
  padding: 5px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .op-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 80px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .op-btn .pushAction {
  color: #03a9f4e6;
  padding: 5px;
  border-radius: 10px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 17px;
  height: 31px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #03a9f4c4 dashed;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .op-btn .sh {
  color: darkseagreen;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .pushAction:hover {
  color: #ffb22b;
  border: 1px #ffb22b dashed;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .checkAction {
  color: transparent;
  padding: 5px;
  border-radius: 7px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #dfe5e7c4 dashed;
  margin-right: 10px;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .checkAction:hover {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .Checked-true {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .details {
  background: #eeeeee7d;
  border-radius: 0 0 10px 10px;
  direction: rtl;
  font-family: Amiri, serif;
  font-size: 11px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 10px 0 0;
  padding: 10px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .details strong {
  color: #03a9f4;
}
.wrapper-NotInOrder .fixContainer .wrapper-listOrder-item .SuppliersList .Supplier .details .title {
  color: #266c8d;
  font-family: "Amiri", serif;
  padding: 0 5px;
}
.wrapper-NotInOrder .products-wrapper {
  display: flex;
  width: 75%;
  flex-wrap: wrap;
  align-content: flex-start;
}
.wrapper-NotInOrder .products-wrapper .Hide {
  opacity: 0.4 !important;
}
.wrapper-NotInOrder .products-wrapper .Zoom {
  width: 315px !important;
  height: 326px !important;
}
.wrapper-NotInOrder .products-wrapper .product {
  float: left;
  margin: 5px;
  border-radius: 8px;
  box-shadow: -2px 2px #ecf0f3;
  background-size: cover;
  background-position: center;
  border: 5px #cdd2cd solid;
  opacity: 0.8;
  width: 215px;
  height: 190px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.wrapper-NotInOrder .products-wrapper .product .product-info {
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: space-evenly;
}
.wrapper-NotInOrder .products-wrapper .product .product-info .checkAction {
  height: 25px;
  width: 25px;
  margin: 2px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px #e5e5e5e8 solid;
  background-color: #787e819c;
}
.wrapper-NotInOrder .products-wrapper .product .product-info .checkAction:hover {
  border: 2px #246893 solid;
  background-color: #048ee4c9;
}
.wrapper-NotInOrder .products-wrapper .product .product-info .checked {
  border: 2px #246893 solid;
  background-color: #048ee4c9;
}
.wrapper-NotInOrder .products-wrapper .product .product-info .checked:hover {
  border: 2px #e5e5e5e8 solid;
  background-color: #787e819c;
}
.wrapper-NotInOrder .products-wrapper .product .product-info .QUnV_ {
  color: white;
  background-color: #058723bf;
  height: 34px;
  width: 35px;
  text-align: center;
  margin: 5px;
  padding: 5px;
  border-radius: 4px;
  font-size: 21px;
}
.wrapper-NotInOrder .products-wrapper .product .product-info ._code_ {
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  color: #ffffff;
  padding: 1px 6px 1px 6px;
  background-color: #238dd9;
  font-size: 14px;
}
.wrapper-NotInOrder .products-wrapper .product:hover {
  border: #ffb22be8 solid 5px;
  opacity: 0.9 !important;
  cursor: pointer;
}
.wrapper-NotInOrder .products-wrapper .Selected {
  border: #13a0e3 solid 5px;
  opacity: 0.9;
  cursor: pointer;
}
.wrapper-NotInOrder .products-wrapper .item-checked {
  border: #ffb22be8 solid 5px;
  opacity: 0.9;
  cursor: pointer;
}
.wrapper-NotInOrder .products-wrapper i {
  margin: 8px;
  color: #161816;
  font-size: 20px;
}
.wrapper-NotInOrder .products-wrapper .eyeView {
  float: right;
  color: #7a9999;
}
.wrapper-NotInOrder .products-wrapper ._code_ strong {
  color: #ffffff;
}

@media screen and (max-device-width: 430px) {
  .LeftSidebar {
    background-color: #eeeeee8a;
    border-radius: 0 10px 18px 0;
    float: left;
    left: 0;
    padding: 35px 5px 5px;
    position: fixed;
    /* top: 107px; */
    z-index: 99;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .wrapper-NotInOrder {
    display: flex;
    flex-direction: column;
    padding-top: 1px !important;
    align-items: flex-end;
  }
  .wrapper-NotInOrder .fixContainer {
    width: 90% !important;
    height: -moz-fit-content;
    display: flex;
    justify-content: space-around;
  }
  .wrapper-NotInOrder .fixContainer .wrapper-listOrder-item {
    position: relative;
    margin-right: 0px !important;
  }
  .wrapper-NotInOrder .products-wrapper {
    display: flex;
    width: 90%;
    align-content: flex-start;
    justify-content: center;
    padding-top: 15px;
  }
  .wrapper-NotInOrder .products-wrapper .product {
    float: left;
    margin: 5px;
    border-radius: 8px;
    box-shadow: -2px 2px #ecf0f3;
    background-size: cover;
    background-position: center;
    border: 5px #cdd2cd solid;
    opacity: 0.8;
    width: 45%;
    height: 160px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info {
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: space-evenly;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info .checkAction {
    height: 25px;
    width: 25px;
    margin: 2px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px #e5e5e5e8 solid;
    background-color: #787e819c;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info .checkAction:hover {
    border: 2px #246893 solid;
    background-color: #048ee4c9;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info .checked {
    border: 2px #246893 solid;
    background-color: #048ee4c9;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info .checked:hover {
    border: 2px #e5e5e5e8 solid;
    background-color: #787e819c;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info .QUnV_ {
    color: white;
    background-color: #058723bf;
    height: 34px;
    width: 35px;
    text-align: center;
    margin: 5px;
    padding: 5px;
    border-radius: 4px;
    font-size: 21px;
  }
  .wrapper-NotInOrder .products-wrapper .product .product-info ._code_ {
    border-radius: 5px;
    width: -moz-fit-content;
    width: fit-content;
    color: #ffffff;
    padding: 1px 6px 1px 6px;
    background-color: #238dd9;
    font-size: 14px;
  }
  .wrapper-NotInOrder .products-wrapper .product:hover {
    border: #ffb22be8 solid 5px;
    opacity: 0.9 !important;
    cursor: pointer;
  }
  .wrapper-NotInOrder .products-wrapper .Selected {
    border: #13a0e3 solid 5px;
    opacity: 0.9;
    cursor: pointer;
  }
  .wrapper-NotInOrder .products-wrapper .item-checked {
    border: #ffb22be8 solid 5px;
    opacity: 0.9;
    cursor: pointer;
  }

  .fix-tolBar {
    display: block !important;
    height: 110px !important;
  }
  .fix-tolBar .NavControls {
    border: 0 solid #e7e7e7;
    border-radius: 0 0 15px 15px !important;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto 8px !important;
    right: 0;
    width: 100% !important;
  }
  .fix-tolBar .NavControls .Nav-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .fix-tolBar .NavControls .Nav-options .round {
    display: none !important;
  }
  .fix-tolBar .NavControls .Nav-options .SendPush {
    display: none !important;
  }
  .fix-tolBar .NavControls .Nav-options #search {
    width: 80% !important;
    margin-top: 10px;
    height: 35px;
  }
}
/* 
@media screen and (max-device-width: 430px) {
  header {
    grid-area: header;
    background-color: #ffffff;
    color: #21aced;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: -webkit-fill-available;
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: center;

    .main-header {
      display: flex;
      width: 90%;

      .logo {
        width: unset !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .userInfo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        font-size: small;

        .ActivePage {
          width: -moz-fit-content;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
          font-size: small;
          padding: unset !important;
        }

        .userIMG {
          width: 34px !important;
          height: 34px;
          background-color: whitesmoke;
          margin: 5px;
          background-size: cover;
          border-radius: 50%;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: max-content;
          padding: 0px 10px;
        }
      }

      .HeadToolBar {
        display: flex;
        justify-content: center;
      }

      .HeadToolBar {
        display: flex;
        justify-content: center;

        ul {
          display: flex;
          width: 75%;
        }
      }
    }
  }
}
 */